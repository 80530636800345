import '../css/App.css';
import Carousel from "../Components/Carousel";
import PageSection from "../Components/PageSection";
import pharaohBig from "../images/pharaoh-cover-big.jpg";


function Books() {

  return (
    <div>
      <Carousel />

      

</div>

  );
}

export default Books;
