import React from "react";
import logo from "../images/logos/jah-logo-new3.png";
import '../css/header.css';
import PropTypes from "prop-types";

export default class Navigation extends React.Component {
  static propTypes = {
    changePage: PropTypes.func,
    pages: PropTypes.array
};

  click(page) {
    this.props.changePage(page);
  }

  render() {
  return (
    <div class="header">
      <div class="tape-section"></div>
      <div class="header-inner-wrapper">
        <div class="logo">
        <span class="nav-button" onClick={ ()=> {
              this.click("Home");
            } }><img src={logo} alt="James A. Hillebrecht" /></span>
          </div>
        <div class="navigation">
          { this.props.pages.map((page, i) => (
            <span class="nav-button" onClick={ ()=> {
              this.click(page);
            } }>{page}</span>
          )) }
          <a class="nav-button" href="https://blog.jamesahillebrecht.com/">BLOG</a>
          <a class="nav-button" href="https://www.amazon.com/stores/James-A.-Hillebrecht/author/B0BH371GYL">BUY</a>
        </div>
      </div>
      <div class="tape-section"></div>
    </div>
  )}
}

