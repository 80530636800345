import React from "react";
import { Helmet } from "react-helmet";
import '../css/header.css';
import '../css/newsletter.css';


export default class Newsletter extends React.Component {

  render() {
  return (
    <div class="newsletter-section">
  
      
      <Helmet>
        <script>
            {`
            (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
            .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
            n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
            (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
            ml('account', '1122059');
            `}
        </script>
      </Helmet>



      <div class="ml-embedded" data-form="CtabG9"></div>

    </div>
  )}
}

