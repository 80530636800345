import React from "react";

import Navigation from "./Components/Navigation";
import Home from "./Pages/Home";
import Books from "./Pages/Books";
import About from "./Pages/About";
import Blog from "./Pages/Blog";
import Contact from "./Pages/Contact";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import './css/App.css';

import fbIcon from "./images/social-images/facebook1.png";
import goodreadsIcon from "./images/social-images/goodreads.png";
import twitterIcon from "./images/social-images/twitter1.png";


//const pages = ["Home", "Books", "About", "Blog", "Contact"];
const pages = ["Home", "Books", "About", "Contact"];


export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {currentPage: "Home"};
    this.changePage = this.changePage.bind(this);

  }

  changePage = (newPage) => {
    console.log(newPage)
    this.setState({currentPage: newPage});
  }

  render () {
   
   return ( 
    <div className="App">

        <div class="content-header-wrapper">
          <Navigation changePage={this.changePage}  pages={pages} />
          <div class="page-content">

            { this.state.currentPage === "Home" ? <Home />
              : this.state.currentPage === "Books" ? <Books />
              : this.state.currentPage === "About" ? <About />
              // : this.state.currentPage === "Blog" ? <Blog />
              : this.state.currentPage === "Contact" ? <Contact />
              : this.state.currentPage === "PrivacyPolicy" ? <PrivacyPolicy />
              : <Home />  
          
          }


          <div class="footer">
            <div class="footer-content social">
              <a href="https://www.facebook.com/james.hillebrecht"><img src={fbIcon} width="25" height="25"></img></a>
              <a href="https://x.com/JimHillebrecht"><img src={twitterIcon} width="25" height="25"></img></a>
              <a href="https://www.goodreads.com/author/show/6445020.James_A_Hillebrecht"><img src={goodreadsIcon} width="25" height="25"></img></a>
            </div>
            <div class="footer-content copyright">&#169; James A. Hillebrecht. All Rights Reserved</div>
            <div class="footer-content privacy">
                <span class="privacy-button" onClick={ ()=> {
                    this.changePage("PrivacyPolicy");
                  } }>Privacy Policy</span>
            </div>
          </div> 
        </div>
      </div>
    </div>
  )}
}

