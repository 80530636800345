import React from "react";
import PropTypes from "prop-types";

export default class PageSection extends React.Component {
    static propTypes = {
        headerText: PropTypes.string,
        divider: PropTypes.string,
        banner: PropTypes.string,
        sectionContent: PropTypes.array,
        sidePicture: PropTypes.object,
        showOppositeSide: PropTypes.bool,
        sideLink: PropTypes.string,
        overrideSectionContent: PropTypes.bool
    };


    render() {
        var sideClass = this.props.showOppositeSide ? "section-right" : "";

        return (
            <div class={"section " + sideClass}>
                <div class="text-divider">{this.props.divider}</div>
                { this.props.showOppositeSide ? 
                <div class="section-inner-wrapper">
                    <div class="section-image"><a href={this.props.sideLink}><img src={this.props.sidePicture} /></a></div>
                    <div class="section-text">
                        <div class="section-header-wrapper">
                            <div class="section-header">{this.props.headerText}</div>
                            <div class="banner">{this.props.banner}</div>
                        </div>

                        
                        { !this.props.overrideSectionContent ? this.props.sectionContent.map((para, index) => (
                            <p>{para}</p> 
                        )) : this.props.sectionContent }


                    </div>
                </div>
                :
                <div class="section-inner-wrapper">
                    <div class="section-text">
                        <div class="section-header-wrapper">
                            <div class="section-header">{this.props.headerText}</div>
                            <div class="banner">{this.props.banner}</div>
                        </div>

                        
                        { !this.props.overrideSectionContent ? this.props.sectionContent.map((para, index) => (
                            <p>{para}</p>
                        )) : this.props.sectionContent }
                    </div>
                    <div class="section-image"><a href={this.props.sideLink}><img src={this.props.sidePicture} /></a></div>
                </div>
                }
            </div>
        );
}
}
