import '../css/App.css';
import PageSection from "../Components/PageSection";
import johnBlumen from "../images/john_blumen2.jpg"
import jamesImg from "../images/james.jpg";

function About() {

  return (
    <div>

      <PageSection 
        headerText="ABOUT THE AUTHOR" 
        divider="about" 
        showOppositeSide={false}
        banner="More about James" 
        sectionContent={["Jim Hillebrecht has been writing tales of fantasy and fancy for more than four decades now, the works growing with him and he with them.  Along the way, he married Maggie, the love of his life, and raised three astonishing daughters in the town of Latrobe PA (home of Mr. Rogers, Arnold Palmer, and the banana split).  Now retired, he made a living working for an international carbide company, starting as a truck driver/janitor and working up to senior metallurgical engineer in a career that spanned 45 years.",
          "On his inspiration: \"I don't choose the stories, they choose me.  Character quirks, pieces of plotlines, intriguing settings all settle in the back of my brain and slowly simmer and stew, finally combining together to form the idea for a book.  And the only way to get them out of my head is to write them."
        ]}
        sideLink="https://www.amazon.com/stores/James-A.-Hillebrecht/author/B0BH371GYL"
        sidePicture={jamesImg}
      />

    <PageSection 
        headerText="ABOUT THE ILLUSTRATOR" 
        divider="Illustrations" 
        showOppositeSide={true}
        banner="More about John" 
        sectionContent={["With an eclectic portfolio that runs the gamut from industrial design to children's books, John's 35 year career in advertising and publishing reflects his wide range of expertise in the multi-faceted world of illustration and design.",
            "After years of perfecting his techniques with traditional media, John chose to apply his creative knowledge and artistic insight to the discipline of digital illustration. This seamless transition from canvas to computer has yielded some of the most imaginative and thought-provoking imagery in John's career.",
            "John lives and works as a freelance illustrator in Pittsburgh, Pennsylvania. His images grace the covers of books published by The Penguin Group, Tor, Harcourt Publishing and Llewellyn Worldwide and a myriad of other publications and periodicals. His work also appears in the fantasy art showcases Spectrum, Fantasy Art Now, Aphrodisia and Erotic Fantasy Art, Now."
        ]}
        sideLink="https://www.johnblumenillustration.com/"
        sidePicture={johnBlumen}
      />



</div>

  );
}

export default About;
