import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../css/carousel.css';

import ConductorImg from '../images/carousel-images/Conductor_small2.jpg';
import PaladinDaughterImg from '../images/carousel-images/PD_small1.jpg';
import PharaohImg from '../images/carousel-images/pharaoh-cover-small2.png';
import RITHImg from '../images/carousel-images/RITH_small1.jpg';
import UTWOSImg from '../images/carousel-images/UTWOS_small1.jpg';
import DAImg from '../images/carousel-images/da_fixed1.png';
import WizImg from '../images/carousel-images/wizards-heir.jpg';
import HeavensImg from '../images/carousel-images/heavens-warrior.jpg';
import HouseImg from '../images/carousel-images/house-cover-medium.png';


export default () => {
  
  
  return (
    <div class="carousel-wrapper">
      
    <Swiper
      slidesPerView='auto'
      autoplay={{
        delay: 1500,
        disableOnInteraction: true,
      }}
      freeMode= {true}
      spaceBetween={5}
      pagination={{ clickable: true }}
      loop={true}
      modules={[Pagination, Navigation, Scrollbar, A11y, Autoplay]}
      className="mySwiper"
      grabCursor= {true}
    >
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/Conductor-James-Hillebrecht-ebook/dp/B0BRSV9RMN"><img src={ConductorImg} /></a></span></SwiperSlide>
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/Pharaohs-Mountain-James-Hillebrecht-ebook/dp/B0D8L6H5HR?ref_=ast_author_mpb"><img src={PharaohImg} width="179" height="288" /></a></span></SwiperSlide>
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/Rage-Heavens-Book-Paladin-Trilogy-ebook/dp/B0818W9LQW"><img src={RITHImg} /></a></span></SwiperSlide>
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/Upon-This-World-Stone-Paladin-ebook/dp/B0818Y3GC8"><img src={UTWOSImg} /></a></span></SwiperSlide>
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/Darkness-Ascending-Three-Paladin-Trilogy-ebook/dp/B081B8DW51"><img src={DAImg} /></a></span></SwiperSlide>
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/PALADINS-DAUGHTER-Book-Gorgorin-Wars-ebook/dp/B07W7C447M"><img src={PaladinDaughterImg} /></a></span></SwiperSlide>
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/Wizards-Heir-Second-Book-Gorgorin-ebook/dp/B07YCSX2W7"><img src={WizImg} /></a></span></SwiperSlide>
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/Heavens-Warrior-Third-Book-Gorgorin-ebook/dp/B07YM1WYFX"><img src={HeavensImg}  /></a></span></SwiperSlide>
      <SwiperSlide><span class="carousel-book"><a href="https://www.amazon.com/HOUSE-AMONGST-DUNES-James-Hillebrecht-ebook/dp/B09P1VD92W"><img src={HouseImg} /></a></span></SwiperSlide>
    </Swiper> 

    </div>

  );
};