
import '../css/App.css';
import PageSection from "../Components/PageSection";
import pharaohSmall from "../images/carousel-images/pharaoh-cover-small2.png";
import rageSmall from "../images/carousel-images/RITH_small1.jpg";
import Newsletter from '../Components/Newsletter';


function Contact() {

  return (
    <div>

      <PageSection 
        headerText="CONTACT THE AUTHOR" 
        divider="contact" 
        showOppositeSide={false}
        banner="How to reach James and his team" 
        overrideSectionContent={true}
        sectionContent={<div>
          <p>James can be contacted via email at <a href="mailto:james@jamesahillebrecht.com">james@jamesahillebrecht.com</a>, but beware! He receives a lot of emails and may not reply immediately.</p>
          <p>For LITERARY INQUIRIES or to required an appearance by James, email his team at <a href="mailto:inquiries@jamesahillebrecht.com">inquiries@jamesahillebrecht.com</a>.</p>
          <p>For any OTHER INQUIRIES or to report an issue with the website, email the administrator at <a href="mailto:admin@jamesahillebrecht.com">admin@jamesahillebrecht.com.</a></p>
        </div>}
        sidePicture={rageSmall}
        sideLink="https://www.amazon.com/Rage-Heavens-Book-Paladin-Trilogy-ebook/dp/B0818W9LQW"
      />


  <Newsletter/>

</div>

  );
}

export default Contact;
