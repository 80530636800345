import '../css/App.css';
import Carousel from "../Components/Carousel";
import PageSection from "../Components/PageSection";
import pharaohBig from "../images/pharaoh-cover-big.jpg";
import RITHBig from "../images/RITH_big.jpg";
import PDBig from "../images/PD_big.jpg";
import Newsletter from '../Components/Newsletter';

function Home() {

  return (
    <div>

      <Carousel />

      <PageSection 
        headerText="Pharaoh's Mountain" 
        divider="New Release" 
        showOppositeSide={false}
        banner="Can a Graverobber's Defiance Shatter an Empire?" 
        sectionContent={["The Great Tomb of the Pharaoh Khufu, a monstrous thing in pyramid shape, dominates all Egypt.", 
          "The labor of thirty years by an entire kingdom, the Tomb is the confirmation of the superiority of the 4th Dynasty over all the rulers who came before. And the monument in whose shadow the generations of Khufu's heirs will forever shelter against any who would seek to overthrow them.",
          "No graverobber would dream of attacking the Great Tomb. It is ringed by temples of dedicated priests, guarded by the regiment that had protected Khufu in life, and defended by the ingenuity of legions of architects and the curses of a dozen gods.",
          "Yet its very prestige may be its undoing. For if the Tomb could be violated within weeks of Khufu's internment, its entire meaning would be negated, proving to the world that even with the expenditure of such labor, treasure, and cunning, the new Dynasty is unable to protect their dead.",
          "So for those who have chafed under Khufu's iron fist, the first blow of their rebellion must not be against flesh. But against stone.",
          "The rebels soon find the man they need: Dahshur, an experienced graverobber who has broken a hundred tombs. A man who despises priests, mocks kings, and has nothing but contempt for the magics woven to protect the dead. An atheist in the Land of the Gods.",
          "As the new Pharaoh's army strikes the rebels in Upper Egypt, Dahshur's team moves against the Tomb. And they succeed in breaking through. Only to find themselves enmeshed in dark plots, diabolic traps, and the growing, terrifying certainty they have unleashed the wrath of the gods."
        ]}
        sideLink="https://www.amazon.com/Pharaohs-Mountain-James-Hillebrecht-ebook/dp/B0D8L6H5HR"
        sidePicture={pharaohBig}
      />

      <PageSection 
        headerText="PRAISE FOR JAMES' WORK" 
        divider="Reviews" 
        showOppositeSide={true}
        banner="Reviews from Amazon" 
        sectionContent={["\"From having an extensive history of reading Forgotten Realms, Dragonlance, Ravenloft Series Novels, along with Tolkien and of course the many works of R.A. Salvatore... this trilogy fits right in with the best of them, the realms are different but it seems based on the same AD&D principles of the others and a thoroughly good read.\" - Amazon Customer",
          "\"To put it simply, I adored a Rage in the Heavens...as a reader it was wonderful to let myself be swept away by such an epic tale, with its epic battles and terrible villains, rooting for the heroes to come out on top. I started the next volume in the trilogy, Upon This World of Stone, as soon as I finished the first!\" - K.E. Bucher"
        ]}
        sideLink="https://www.amazon.com/Rage-Heavens-Book-Paladin-Trilogy-ebook/dp/B0818W9LQW"
        sidePicture={RITHBig}
      />

      {/* <PageSection 
        headerText="Join our newsletter" 
        divider="newsletter" 
        showOppositeSide={false}
        banner="Keep up to date" 
        sectionContent={["The newsletter is the best way to keep in touch with James. Join to keep up to date with his works.",
          "Coming Soon!"
        ]}
        sideLink="https://www.amazon.com/PALADINS-DAUGHTER-Book-Gorgorin-Wars-ebook/dp/B07W7C447M"
        sidePicture={PDBig}
      /> */}


      <Newsletter />


</div>

  );
}

export default Home;
